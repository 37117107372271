import React from "react";
import Jumbo from "./Jumbo";

export default function HomePage() {
  return (
    <>
      <Jumbo />
      <footer />
    </>
  );
}
